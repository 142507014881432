<template>
	<div class="row">
		<div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-6 col-md-4 col-xl-5 mb-3">
                     <label><i class="far fa-backpack color-theme font-11 me-1"></i> Pacote</label>
                     <v-select title="Desktop" placeholder="Selecione um pacote" :options="listaPacotes" label="nome" v-model="pesquisa.pacote">
                        <template v-slot:no-options>Sem resultados</template>
                     </v-select>
                     <select title="Mobile" class="form-control" v-model="pesquisa.pacote">
                        <option v-for="(option, index) in listaPacotes" :key="index" :value="option">{{ option.nome }}</option>
                     </select>
                  </div>
                  <div class="col-6 col-md-4 col-xl-5 mb-3">
                     <label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
                     <datePicker :format="'DD/MM/YYYY'" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
                  <div class="col-12 col-md-4 col-xl-2 mb-3 align-self-end text-center">
							<button type="button" class="btn btn-primary w-100" @click="buscarPedidos"><i class="fas fa-search font-13"></i></button>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<div class="col-12" v-for="(compra, index) in pesquisa.resultado" :key="index">
         <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
            <div class="card-body p-3 pe-1">
               <div class="row m-0 font-12">
                  <div class="col-md-8 px-1 align-self-center cursor-pointer" @click="abrirModal(compra)">
                     <div class="row m-0">
                        <div class="col-12 p-0 text-center text-md-start mb-2">
                           <div class="limitador font-16 weight-600">{{ formatDateDay(compra.dataCompra) }} {{ formatDateTime(compra.dataCompra) }}</div>
                        </div>
                        <div class="col-6 p-0 d-none d-md-inline">
                           <div class="limitador">
                              <i class="far fa-box color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Pacotes:</strong> 
                              {{ compra.pacote.length }}
                           </div>
                        </div>
                        <div class="col-6 p-0 d-none d-md-inline">
                           <div class="limitador">
                              <i class="far fa-dollar-sign color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Pgto:</strong> 
                              {{ compra.pagamento == null ? 'Desconhecido' : compra.pagamento }}
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-14">
                     <span class="limitador-2 cursor-pointer" @click="alterarStatus(compra, index)">
                        <i class="far fa-sync font-13"></i> <br/> 
                        <strong title="Status" :class="compra.status == 'Finalizado' ? 'text-success' : compra.status == 'Cancelado' ? 'text-danger' : 'text-warning'">{{ compra.status }}</strong>
							</span>
                  </div>

                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-15">
                     <div class="limitador-2">
                        <small class="font-14 color-theme">R$</small>
                        <strong title="Valor"> {{ compra.valorTotal == null ? 'Desconhecido' : parseFloat(compra.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</strong>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12 text-center my-5" v-if="pesquisa.resultado.length == 0">Nenhum resultado encontrado</div>
		
		<!-- modalVisualizar -->
		<div class="modal fade" id="modalVisualizar" tabindex="-1" aria-labelledby="modalVisualizarLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalVisualizarLabel">Pedido (Nº {{ modal.id }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1">
                        <div class="card mb-2">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Informações do pedido</h6>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">Nome do cliente: </strong> {{ modal.nomeCompleto }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">ID Cliente: </strong> {{ modal.idUsuario }}
                                 </div>
                                 <div class="col-12 px-1 mb-1" :class="modal.status == 'Finalizado' ? 'text-success' : modal.status == 'Cancelado' ? 'text-danger' : 'text-warning'">
                                    <strong class="color-theme me-1">Status: </strong> <strong>{{ modal.status }}</strong>
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">Valor total: </strong> R$ {{ parseFloat(modal.valorTotal).toFixed(2) }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
                                    <strong class="color-theme me-1">Forma de pagamento: </strong> {{ modal.pagamento }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
												<strong class="color-theme me-1">ID transação: </strong> {{ modal.transactionId != null ? modal.transactionId : 'Não encontrado' }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
												<strong class="color-theme me-1">Pedido realizado: </strong>
                                    {{ new Date(modal.dataCompra.year +'/'+ modal.dataCompra.monthValue +'/'+ modal.dataCompra.dayOfMonth +' '+ modal.dataCompra.hour +':'+ modal.dataCompra.minute).toLocaleString() }}
                                 </div>
                                 <div class="col-12 px-1 mb-1">
												<strong class="color-theme me-1">Última movimentação: </strong>
                                    {{ new Date(modal.dataUltMov.year +'/'+ modal.dataUltMov.monthValue +'/'+ modal.dataUltMov.dayOfMonth +' '+ modal.dataUltMov.hour +':'+ modal.dataUltMov.minute).toLocaleString() }}
                                 </div>
											<div class="col-12 px-1 mb-1">
												<strong class="color-theme me-1">Url pagamento: </strong> {{ modal.urlPagamento != null ? modal.urlPagamento : 'Não encontrado' }}
											</div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-12 px-1">
                        <div class="card mb-2">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">Pacotes</h6>
                                    <hr>
                                 </div>
                                 
                                 <div class="col-12 px-1" v-for="(produto, index) in modal.pacote" :key="index">
												<div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''">
													<div class="card-body">
														<h6>{{ produto.nome }}</h6>
														<p class="mb-0"><small class="color-theme">R$</small> {{ parseFloat(produto.valor).toFixed(2) }}</p>
													</div>
												</div>
											</div>
                                 <div class="col-12 px-1 py-5 text-center" v-if="modal.pacote == null || modal.pacote.length == 0">Nenhum pacote encontrado</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
	name: 'Pedidos',
	data : function () {
		return {
			pesquisa: {'pacote': null, 'data': [new Date(), new Date()], 'resultado': []},
			modal: {
				'id': null, 'idUsuario': null, 'nomeCompleto': null, 'transactionId': null, 'idPagamento': null, 'dataCompra': {}, 'dataUltMov': {}, 
				'status': null, 'pagamento': null, 'urlPagamento': null, 'valorTotal': 0, 'pacote': []
			}
		}
	},
   computed: {
      ... mapState({
			urlRest: state => state.urlRest,
         dadosUsuario: state => state.dadosUsuario,
			listaPacotes: state => state.listaPacotes
      })
   },
	methods: {
		buscarPedidos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.resultado = []

			this.$axios({
				method: 'post',
				url: this.urlRest +'pagamento/searchPedidos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
				
			}).then(response => {
				this.pesquisa.resultado = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		alterarStatus : function (compra, index) {
			let ref = this;

         Swal.fire({
            icon: 'info',
            title: 'Alterar status',
            input: 'select',
            inputOptions: {
               'Aguardando pagamento': 'Aguardando pagamento',
               'Cancelado': 'Cancelado',
               'Finalizado': 'Finalizado'
            },
            inputValue: compra.status,
            inputPlaceholder: 'Selecione um status',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            inputValidator: (value) => {
               if (!value) {
                  return 'Opção inválida!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'pagamento/alterarstatuscompra',
                  params:{
                     idVenda: compra.id,
                     status: result.value
                  }
               }).then(function () {
                  ref.pesquisa.resultado[index].status = result.value

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Status salvo!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogar')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
		abrirModal : function (compra) {
			this.modal = JSON.parse(JSON.stringify(compra))
			$('#modalVisualizar').modal('show')
		},
		formatDateDay(data) {
         const { dayOfMonth, month, year } = data;

         // Use padStart para adicionar um zero à esquerda se for necessário
         const formattedDayOfMonth = String(dayOfMonth).padStart(2, '0');
         const formattedMonth = this.getMonthNumber(month);
         const formattedYear = year;

         const formattedDate = `${formattedDayOfMonth}/${formattedMonth}/${formattedYear}`;

         return formattedDate;
      },
      formatDateTime(data) {
			const { hour, minute } = data;

			const formattedHour = String(hour).padStart(2, '0');
			const formattedMinute = String(minute).padStart(2, '0');

			const formattedTime = `${formattedHour}:${formattedMinute}`;

			return formattedTime;
		},
		getMonthNumber(month) {
			const months = {
			JANUARY: '01',
			FEBRUARY: '02',
			MARCH: '03',
			APRIL: '04',
			MAY: '05',
			JUNE: '06',
			JULY: '07',
			AUGUST: '08',
			SEPTEMBER: '09',
			OCTOBER: '10',
			NOVEMBER: '11',
			DECEMBER: '12'
			};

			return months[month];
		}
	},
	mounted () {
      this.$store.dispatch('searchAllPacotes')
	}
}

</script>